import React from 'react';

import SignUpForm from '../../components/SignUpForm/SignUpForm';
import EmptyLayout from '../../layouts/EmptyLayout/EmptyLayout';

const SignUpPage: React.FC = () => {
  return <EmptyLayout body={<SignUpForm />} />;
};

export default SignUpPage;
