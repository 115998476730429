import { useAppSelector } from '../../store';

export default function hasSubscription(): boolean {
  const user = useAppSelector(state => state.userState.user);

  if (!user) {
    return false;
  }

  return user.isPro;
}
