import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ICode } from './types';

interface ICodeState {
  code: ICode;
}

const initialState: ICodeState = {
  code: {
    head: '',
    body: '',
    css: '',
  },
};

export const slice = createSlice({
  initialState,
  name: 'codeSlice',
  reducers: {
    setCode: (state, action: PayloadAction<ICode>) => {
      state.code = action.payload;
    },
  },
});

export default slice.reducer;

export const { setCode } = slice.actions;
