import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IUser } from './types';

interface User {
  user: IUser | null;
  token: string | null;
}

const initialState: User = {
  user: null,
  token: null,
};

export const slice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => initialState,
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
  },
});

export default slice.reducer;

export const { logout, setUser, setToken } = slice.actions;
