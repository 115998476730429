import React from 'react';
import { Box } from '@mui/material';
import Footnote from '../Footnote/Footnote';

const Divider: React.FC = () => {
  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Footnote sx={{ textAlign: 'center' }}>{/* or */}или</Footnote>
    </Box>
  );
};

export default Divider;
