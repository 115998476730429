import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setPerson } from './slice';
import type { IPerson } from './types';
import getBaseUrl from '../../utils/helpers/getBaseUrl';

// Для платежей
export const personApi = createApi({
  reducerPath: 'personApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl('users'),
  }),
  tagTypes: ['Person'],
  endpoints: builder => ({
    getPerson: builder.query<IPerson, string>({
      query: uid => ({ url: `/${uid}` }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setPerson(data));
        } catch (error) {
          // console.error(error);
        }
      },
    }),
  }),
});

export const { useGetPersonQuery } = personApi;
