import React, { useState } from 'react';

import RootNavigation from './RootNavigation';

const Navigation = () => {
  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) return <></>;

  return <RootNavigation />;
};
export default Navigation;
