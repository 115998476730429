import { createTheme } from '@mui/material/styles';
import { LinkProps } from '@mui/material/Link';
import LinkBehavior from './utils/LinkBehaviour';

import BasierCircleSemiBold from './static/fonts/BasierCircle/BasierCircle-SemiBold.woff2';
import BasierCircleMedium from './static/fonts/BasierCircle/BasierCircle-Medium.woff2';
import BasierCircleRegular from './static/fonts/BasierCircle/BasierCircle-Regular.woff2';

// TODO Поправить Mui-error

// {
//   html: { lineHeight: 1, height: '100%', minHeight: '100%' },
//   body: {
//     lineHeight: 1,
//       height: '100%',
//       minHeight: '100%',
//       color: '#1a1a1a',
//   },
//   '#root': { height: '100%', minHeight: '100%' },
// }

const theme = createTheme({
  spacing: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '.MuiAlert-icon': {
            display: 'none',
          },
        },
        action: {
          padding: '0 0 0 8px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          label: {
            color: 'rgba(0, 0, 0, 0.87) !important',
            position: 'relative',
            margin: 0,
            top: 0,
            left: 0,
            transform: 'none',
            fontSize: '14px',
            fontWeight: 600,
            padding: '0 16px 8px',
          },
          '& .MuiInputBase-multiline': {
            padding: 0,
            background: '#fff !important',
            transform: 'none',
          },
          textarea: {
            padding: '7px 17px',
            '&:focus': {
              // background: '#f4f4f4',
            },
          },
        },
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     select: {
    //       margin: '100px 0',
    //     },
    //   },
    // },
    // MuiNativeSelect: {
    //   styleOverrides: {
    //     root: {
    //       background: '#000 !important',
    //       margin: '100px 0',
    //     },
    //     select: {},
    //   },
    // },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 16,
          // background: '#f4f4f4',
          background: '#fff !important',
          '&:hover': {
            // background: '#f4f4f4',
            background: '#fff',
          },
          '&:focus': {
            // background: '#f4f4f4',
            // background: '#fff !important',
          },
          '&.Mui-error': {
            background: '#ffcdd2',
          },
        },
        input: {
          padding: '7px 17px',
          minHeight: 34,
          height: 'auto',
          fontSize: '15px',
          '&:-webkit-autofill': {
            borderRadius: 16,
          },
        },
        // error: {
        //   background: '#e57373',
        // },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: { fontSize: 24, lineHeight: '28px', fontWeight: 600 },
        body1: { fontSize: 16, lineHeight: '24px', fontWeight: 500 },
        body2: {
          fontSize: 16,
          lineHeight: '24px',
          fontWeight: 500,
          color: '#6e6e6e',
        },
      },
    },
    MuiButtonBase: { defaultProps: { disableRipple: true } },
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Basier Circle';
            font-display: swap;
            font-weight: 400;
            src: url(${BasierCircleRegular});
          }
          
          @font-face {
            font-family: 'Basier Circle';
            font-weight: 500;
            font-display: swap;
            src: url(${BasierCircleMedium});
          }
          
          @font-face {
            font-family: 'Basier Circle';
            font-weight: 600;
            font-display: swap;
            src: url(${BasierCircleSemiBold});
          }
          html, body, #root {line-height: 1; height: 100%; min-height: 100%;background-color: #f6f7f8;}
          body { color: #1a1a1a; }
          a { color: #1a1a1a !important; text-decoration-color: #1a1a1a !important; }
          .error.Mui-error, .error > .Mui-error {
            // background: #ffcdd2;
          }
          .ace_editor * {
            font-family: Monaco, Menlo, "Ubuntu Mono", Consolas, "Source Code Pro", source-code-pro, monospace !important;
          }
          .ace_placeholder {
            padding: 0 !important;
            margin: 0 !important;
            line-height: 16px !important;
            font-size: 12px !important;
            transform: none !important;
            color: #1a1a1a !important;
          }
          .ace_hidden-cursors {
            display: none !important;
          }
          // .MuiDataGrid-columnHeaders { display: none !important; }
          // .MuiDataGrid-virtualScroller { margin-top: 0 !important; }
          // .MuiDataGrid-row { 
          //   min-height: 24px !important;
          //   background: none !important;
          //   border: none !important; 
          // }
          .MuiDataGrid-cell { 
            // min-height: 24px !important;
            // padding: 0 !important;
            // background: none !important;
            border: none !important;
            outline: none !important;
            font-size: 16px !important;
            font-weight: 600 !important;
            // color: #6e6e6e;
          }
          .MuiDataGrid-root {
            border: none !important;
          }
          .MuiDataGrid-footerContainer {
            border: none !important;
          }
          .MuiDataGrid-footerContainer p {
            font-size: 16px !important;
            font-weight: 600 !important;
            color: #6e6e6e;
            margin-bottom: 0;
          }
          .MuiDataGrid-columnSeparator {
            display: none !important;
          }
          .MuiDataGrid-columnHeader {
            outline: none !important;
            color: #6e6e6e;
          }
          .positive {
            color: #4caf50;
          }
          .negative {
            color: #d32f2f;
          }
          .MuiNativeSelect-root {
            display: block;
            &:before {
              display: none !important;
            }
            select {
              height: 48px;
              padding: 6px 16px !important;
              border-radius: 16px !important;
              height: 48px;
              background: #fff !important;
              box-sizing: border-box;
            }
            svg {
              right: 16px;
            }
          }
      `,
    },
  },
  palette: {
    primary: {
      light: '#faacd3',
      main: '#c67ca2',
      dark: '#944e73',
      contrastText: 'rgba(255, 255, 255, 0.87)',
    },
    secondary: {
      light: '#f0ffff',
      main: '#bcf7ff',
      dark: '#8ac4cc',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      'Basier Circle',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'Noto Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(','),
  },
});

export default theme;
