import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../../store';
import Preview from '../../components/Preview/Preview';
import { TwoColumnLayout } from '../../layouts';

interface IPrivateRoute {
  element: JSX.Element;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}
const ProRoute = ({ element, iframeRef }: IPrivateRoute) => {
  const user = useAppSelector(state => state.userState.user);

  if (!user) {
    return <Navigate to="/signin" replace />;
  }

  if (!user.isPro) {
    return <Navigate to="/" replace />;
  }

  return (
    <TwoColumnLayout left={element} right={<Preview iframeRef={iframeRef} />} />
  );
};

export default ProRoute;
