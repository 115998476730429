import React from 'react';

import ThemeList from '../../components/ThemeList/ThemeList';

interface Props {
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const ThemesPage: React.FC<Props> = ({ iframeRef }) => {
  return <ThemeList iframeRef={iframeRef} />;
};

export default ThemesPage;
