import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setThemes } from './slice';
import { RootState } from '../index';
import getBaseUrl from '../../utils/helpers/getBaseUrl';
import { ITheme } from './types';
import { ISite } from '../site/types';
import { setSite } from '../site/slice';

export const themesApi = createApi({
  reducerPath: 'themesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl('themes'),
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).userState.token ||
        localStorage.getItem('access_token');

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Theme'],
  endpoints: builder => ({
    getThemes: builder.query<ITheme[], null>({
      query: () => '',
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setThemes(data));
        } catch (error) {
          // console.error(error);
        }
      },
    }),
    changeTheme: builder.mutation<ISite, Partial<ISite>>({
      query: ({ ...patch }) => ({
        url: 'change',
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSite(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetThemesQuery, useChangeThemeMutation } = themesApi;
