import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { InferType, object, string } from 'yup';

import Button from '../../atoms/Button';
import Spacing from '../../atoms/Spacing';
import theme from '../../../theme';
import { useResetPasswordMutation } from '../../../store/api/authApi';
import { TextField } from '../../index';
import { setSnackbarMessage } from '../../../store/slices/appSlice';

const validationSchema = object({
  code: string().required(),
  password: string().min(8).required(),
});

type InputType = InferType<typeof validationSchema>;

const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues: InputType = {
    code: '',
    password: '',
  };

  const [resetPassword, { isLoading, isSuccess, isError }] =
    useResetPasswordMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: InputType) => {
      resetPassword(values);
    },
  });

  useEffect(() => {
    // if (isSuccess) {
    //   navigate('/');
    // }

    if (isError) {
      dispatch(
        setSnackbarMessage({
          message:
            "We're sorry, but an unhandled error occurred. Please try again later.",
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isLoading]);

  return (
    <>
      <TextField
        id="code"
        autoFocus
        placeholder="Enter the activation code"
        value={formik.values.code}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.code && formik.touched.code}
        fullWidth
      />
      <Spacing size={theme.spacing(1)} />
      <TextField
        id="password"
        type="password"
        placeholder="Enter a new password"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.password && formik.touched.password}
        fullWidth
      />
      <Spacing size={theme.spacing(1)} />
      <Button
        color="primary"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        onClick={formik.submitForm}>
        Reset password
      </Button>
    </>
  );
};

export default ResetPasswordForm;
