const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

const getBaseUrl = (uri = '') => {
  if (process.env.NODE_ENV === 'development') {
    return `${BASE_URL}/api/v1/${uri}`;
  }

  return `${BASE_URL}/api/v1/${uri}`;
};

export default getBaseUrl;
