import React from 'react';

const reloadIframe = (
  iframeRef: React.RefObject<HTMLIFrameElement>,
  searchParams = {},
) => {
  if (iframeRef.current !== null) {
    if (Object.entries(searchParams).length > 0) {
      const newSrc = new URL(iframeRef.current.src.split('?')[0]);
      for (const name in searchParams) {
        // @ts-ignore
        newSrc.searchParams.append(name, searchParams[name]);
        iframeRef.current.src = newSrc.toString();
      }
    } else {
      iframeRef.current.src += '';
    }
  }
};

export default reloadIframe;
