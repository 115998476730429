import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Snackbar, Alert, Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {
  BuilderPage,
  CodePage,
  ForgotPasswordPage,
  SignInPage,
  NotionAuthPage,
  PricingPage,
  ResetPasswordPage,
  SignUpPage,
  ThemesPage,
  SocialsPage,
  UserEditPage,
} from '../pages';
import { GuestOnlyRoute, PrivateRoute, ProRoute } from './components';
import { useAppSelector } from '../store';
import { clearSnackbarMessage } from '../store/slices/appSlice';
import { useDispatch } from 'react-redux';
import { useGetMeQuery, userApi } from '../store/user/api';
import { logout, setToken } from '../store/user/slice';

interface RootNavigationProps {
  string?: string;
}

const RootNavigation = ({ string }: RootNavigationProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { data: user, error, isLoading } = useGetMeQuery(null);
  const dispatch = useDispatch();

  const snackbarMessage = useAppSelector(
    state => state.appState.snackbarMessage,
  );

  const appIsLoaded = useAppSelector(state => state.appState.isLoaded);

  const onClose = () => {
    dispatch(clearSnackbarMessage());
  };

  useEffect(() => {
    if (typeof error !== 'undefined' && user) {
      dispatch(logout());
      localStorage.removeItem('access_token');
      // navigate('/signin');
    }
  }, [isLoading, user]);

  if (!appIsLoaded) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  //
  //
  //
  // const iframe = <iframe ref={iframeRef} src={`http://xeor.dd/taboratrade/`} />;

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/signin"
            element={<GuestOnlyRoute element={<SignInPage />} />}
          />
          <Route
            path="/signup"
            element={<GuestOnlyRoute element={<SignUpPage />} />}
          />
          <Route
            path="/forgot-password"
            element={<GuestOnlyRoute element={<ForgotPasswordPage />} />}
          />
          <Route
            path="/reset-password"
            element={<GuestOnlyRoute element={<ResetPasswordPage />} />}
          />
          <Route
            path="/account"
            element={
              <PrivateRoute element={<UserEditPage />} iframeRef={iframeRef} />
            }
          />
          <Route
            path="/themes"
            element={
              <PrivateRoute
                element={<ThemesPage iframeRef={iframeRef} />}
                iframeRef={iframeRef}
              />
            }
          />
          <Route
            path="/socials"
            element={
              <ProRoute
                element={<SocialsPage iframeRef={iframeRef} />}
                iframeRef={iframeRef}
              />
            }
          />
          <Route
            path="/code"
            element={
              <ProRoute
                element={<CodePage iframeRef={iframeRef} />}
                iframeRef={iframeRef}
              />
            }
          />
          <Route
            path="/notion-get-token"
            element={
              <PrivateRoute
                element={<NotionAuthPage />}
                iframeRef={iframeRef}
              />
            }
          />
          <Route
            path="/pricing"
            element={
              <PrivateRoute element={<PricingPage />} iframeRef={iframeRef} />
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute
                element={<BuilderPage iframeRef={iframeRef} />}
                iframeRef={iframeRef}
              />
            }
          />
        </Routes>
      </BrowserRouter>

      {!error && isLoading && (
        <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarMessage.visible}
        onClose={onClose}>
        <Alert
          onClose={onClose}
          severity={snackbarMessage.severity}
          sx={{
            width: '100%',
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 600,
            alignItems: 'center',
          }}>
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RootNavigation;
