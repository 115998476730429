import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setSite } from './slice';
import type { ISite } from './types';
import { RootState } from '../index';
import getBaseUrl from '../../utils/helpers/getBaseUrl';
import { UserEditInputType } from '../../components/UserEditForm/UserEditForm';
import { setIsLoaded } from '../slices/appSlice';

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl('sites'),
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).userState.token ||
        localStorage.getItem('access_token');

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Site'],
  endpoints: builder => ({
    getMySite: builder.query<ISite, null>({
      query: () => 'my',
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSite(data));
        } catch (error) {
          // console.error(error);
        }
        dispatch(setIsLoaded(true));
      },
    }),
    createMySite: builder.query<ISite, { code: string }>({
      query: body => ({
        url: 'notion/auth',
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSite(data));
        } catch (error) {}
      },
    }),
    updateMySite: builder.mutation<ISite, Partial<ISite>>({
      query: ({ ...patch }) => ({
        url: 'update',
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSite(data));
        } catch (error) {}
      },
    }),
    syncMySite: builder.mutation<ISite, null>({
      query: () => ({
        url: 'sync',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetMySiteQuery,
  useUpdateMySiteMutation,
  useCreateMySiteQuery,
  useSyncMySiteMutation,
} = siteApi;
