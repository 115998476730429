import React from 'react';
import {
  Icon24User,
  Icon24HelpOutline,
  Icon24ServicesOutline,
  Icon24PaletteOutline,
  Icon24DocumentOutline,
  Icon28BracketsSlashSquareOutline,
  Icon28ShareExternalOutline,
} from '@vkontakte/icons';

import {
  Wrapper,
  Button,
  IconWrapper,
  Title,
  Sections,
} from './LeftNavDock.styled';
import logo from '../../static/img/logo-xeor.png';
import hasSite from '../../utils/helpers/hasSite';
import hasSubscription from '../../utils/helpers/hasSubscription';

const LeftNavDock: React.FC = () => {
  const userHasSite = hasSite();
  const userIsPro = hasSubscription();

  return (
    <Wrapper>
      <Button>
        <IconWrapper href="/">
          <img
            src={logo}
            alt="Xeor"
            style={{ display: 'block', maxWidth: '100%' }}
          />
        </IconWrapper>
      </Button>
      <Sections>
        <Button>
          <IconWrapper href="/" sx={{}} title="Настройки сайта">
            <Icon24ServicesOutline />
            <Title>Сайт</Title>
          </IconWrapper>
        </Button>
        <Button>
          <IconWrapper href="#" sx={{ opacity: 0.5 }} title="Скоро!">
            <Icon24DocumentOutline />
            <Title>Страницы</Title>
          </IconWrapper>
        </Button>
        <Button>
          <IconWrapper
            href={userHasSite ? '/themes' : '#'}
            sx={{ opacity: userHasSite ? 1 : 0.5 }}
            title="Темы">
            <Icon24PaletteOutline />
            <Title>Темы</Title>
          </IconWrapper>
        </Button>
        <Button>
          <IconWrapper
            href={userHasSite && userIsPro ? '/socials' : '#'}
            sx={{ opacity: userHasSite && userIsPro ? 1 : 0.5 }}
            title={userIsPro ? 'Социальные сети' : 'Доступно на PRO тарифе'}>
            <Icon28ShareExternalOutline />
            <Title>{/* Socials */}Соцсети</Title>
          </IconWrapper>
        </Button>
        <Button>
          <IconWrapper
            href={userHasSite && userIsPro ? '/code' : '#'}
            sx={{ opacity: userHasSite && userIsPro ? 1 : 0.5 }}
            title={userIsPro ? 'Код' : 'Доступно на PRO тарифе'}>
            <Icon28BracketsSlashSquareOutline />
            <Title>Код</Title>
          </IconWrapper>
        </Button>
      </Sections>
      <div>
        <Button>
          <IconWrapper href="#" sx={{ opacity: 0.5 }} title="Скоро!">
            <Icon24HelpOutline />
          </IconWrapper>
        </Button>

        <Button>
          <IconWrapper href="/account" sx={{}} title="Настройки пользователя">
            <Icon24User />
          </IconWrapper>
        </Button>
      </div>
    </Wrapper>
  );
};

export default LeftNavDock;
