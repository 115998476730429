import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/theme-xcode-css';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/ext-language_tools';
import Button from '../atoms/Button';
import Spacing from '../atoms/Spacing';
import theme from '../../theme';
import { setSnackbarMessage } from '../../store/slices/appSlice';
import {
  useCodeQuery,
  useGetCodeMutation,
  useStoreCodeMutation,
} from '../../store/code/api';
import { setCode } from '../../store/code/slice';
import { useAppSelector } from '../../store';

const CodeForm: React.FC = () => {
  const dispatch = useDispatch();

  // const { data, isLoading } = useCodeQuery(null, {
  //   refetchOnMountOrArgChange: true,
  // });

  const [head, setHead] = useState('');
  const [body, setBody] = useState('');
  const [css, setCss] = useState('');

  const [storeCode, { isLoading: isSaving, isSuccess, isError }] =
    useStoreCodeMutation();

  const [getCode, { isLoading }] = useGetCodeMutation();

  const loadCode = async () => {
    const data = await getCode().unwrap();

    setHead(data?.head || '');
    setBody(data?.body || '');
    setCss(data?.css || '');
  };

  useEffect(() => {
    loadCode();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setSnackbarMessage({
          message: 'Сохранено!',
          visible: true,
          severity: 'success',
        }),
      );
    }

    if (isError) {
      dispatch(
        setSnackbarMessage({
          message: 'Что-то пошло не так. Попробуйте позже.',
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isSaving]);

  const handleSubmit = async () => {
    const newCode = await storeCode({
      head,
      body,
      css,
    }).unwrap();
    console.log(newCode);
    // setCode(newCode);
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: '16px',
          background: '#fff',
          overflow: 'hidden',
          padding: '17px',
        }}>
        <AceEditor
          mode="html"
          height="150px"
          width="100%"
          theme="xcode"
          showGutter={false}
          onChange={setHead}
          name="head"
          value={head}
          fontSize={12}
          showPrintMargin={false}
          highlightActiveLine={false}
          cursorStart={0}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: false,
            tabSize: 2,
          }}
          placeholder="<head></head>"
        />
      </Box>
      <Spacing size={theme.spacing(2)} />
      <Box
        sx={{
          borderRadius: '16px',
          background: '#fff',
          overflow: 'hidden',
          padding: '17px',
        }}>
        <AceEditor
          mode="html"
          height="150px"
          width="100%"
          theme="xcode"
          showGutter={false}
          onChange={setBody}
          name="body"
          value={body}
          fontSize={12}
          showPrintMargin={false}
          highlightActiveLine={false}
          cursorStart={0}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: false,
            tabSize: 2,
          }}
          placeholder="<body></body>"
        />
      </Box>
      <Spacing size={theme.spacing(2)} />
      <Box
        sx={{
          borderRadius: '16px',
          background: '#fff',
          overflow: 'hidden',
          padding: '17px',
        }}>
        <AceEditor
          mode="css"
          height="150px"
          width="100%"
          theme="xcode"
          showGutter={false}
          onChange={setCss}
          name="css"
          value={css}
          fontSize={12}
          showPrintMargin={false}
          highlightActiveLine={false}
          cursorStart={0}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: false,
            tabSize: 2,
          }}
          placeholder="<style></style>"
        />
      </Box>
      <Spacing size={theme.spacing(2)} />

      <Button
        color="primary"
        variant="contained"
        loading={isSaving}
        disabled={isSaving}
        onClick={() => handleSubmit()}>
        Сохранить
      </Button>
    </>
  );
};

export default CodeForm;
