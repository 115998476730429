import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

// color: #6e6e6e;
const Wrapper = styled(Box)(`
  color: #828282;
  font-size: 12px;
  font-weight: 600;
  padding: 0 16px;
`);

interface Props extends BoxProps {
  children?: React.ReactNode;
}

const Footnote: React.FC<Props> = ({ children, ...restProps }) => {
  return <Wrapper {...restProps}>{children}</Wrapper>;
};

export default Footnote;
