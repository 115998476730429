import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IPerson } from './types';

interface IPersonState {
  person: IPerson | null;
}

const initialState: IPersonState = {
  person: null,
};

export const slice = createSlice({
  initialState,
  name: 'personSlice',
  reducers: {
    setPerson: (state, action: PayloadAction<IPerson>) => {
      state.person = action.payload;
    },
  },
});

export default slice.reducer;

export const { setPerson } = slice.actions;
