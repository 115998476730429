import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../Button/Button';
import Spacing from '../Spacing/Spacing';
import theme from '../../theme';
import { usePaymentsStoreMutation } from '../../store/payments/api';
import { useAppSelector } from '../../store';
import { setSnackbarMessage } from '../../store/slices/appSlice';
import { NativeSelect } from '@mui/material';

const TERMINAL_KEY = '1683613048724DEMO';

const PaymentForm: React.FC = () => {
  const dispatch = useDispatch();

  const user = useAppSelector(state => state.userState.user);

  const [paymentsStore, { isLoading, isError }] = usePaymentsStoreMutation();

  useEffect(() => {
    if (isError) {
      dispatch(
        setSnackbarMessage({
          message: 'Что-то пошло не так. Попробуйте позже.',
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isLoading]);

  const onPay = async () => {
    const { id: orderId } = await paymentsStore({
      subscription_plan_id: 1,
      amount: 600,
    }).unwrap();

    // @ts-ignore
    pay({
      amount: { value: '600' },
      description: { value: 'Оплата тарифа' },
      frame: { value: 'false' },
      order: { value: `${orderId}` },
      terminalkey: { value: TERMINAL_KEY },
      email: { value: user?.email || '' },
      language: { value: 'ru' },
    });
  };

  return (
    <>
      <NativeSelect disableUnderline defaultValue={600}>
        <option value={600}>Pro (12 месяцев) 600₽</option>
      </NativeSelect>
      <Spacing size={theme.spacing(2)} />
      <Button type="submit" color="primary" variant="contained" onClick={onPay}>
        Оплатить
      </Button>
    </>
  );
};

export default PaymentForm;
