import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setSocials } from './slice';
import type { ISocial } from './types';
import getBaseUrl from '../../utils/helpers/getBaseUrl';
import { RootState } from '../index';

export const socialsApi = createApi({
  reducerPath: 'socialsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl('socials'),
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).userState.token ||
        localStorage.getItem('access_token');

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Socials'],
  endpoints: builder => ({
    socials: builder.query<ISocial[], void>({
      query: () => '',
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSocials(data));
        } catch (error) {
          // console.error(error);
        }
      },
    }),
    storeSocials: builder.mutation<ISocial[], { [key: string]: string }>({
      query: body => ({
        url: '',
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSocials(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useSocialsQuery, useStoreSocialsMutation } = socialsApi;
