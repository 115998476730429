import { styled, css, keyframes } from '@mui/material/styles';
import { Box, Button, Paper } from '@mui/material';

export const Wrapper = styled(Box)`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 8px;
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const IconWrapper = styled(Paper)`
  box-shadow: none;
  background: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

interface IconButtonProps {
  syncing: string;
}
export const IconButton = styled(Button)<IconButtonProps>`
  color: #1a1a1a;
  margin: 0 auto;
  padding: 0;
  min-width: auto;
  width: 32px;
  height: 32px;
  div {
    animation: ${({ syncing }) =>
      syncing === 'true'
        ? css`
            ${rotateAnimation} 2s linear infinite
          `
        : 'none'};
    margin: 0 auto;
  }
`;
