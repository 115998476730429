import React, { useEffect } from 'react';
import { InferType, object, string } from 'yup';

import Button from '../atoms/Button';
import Spacing from '../atoms/Spacing';
import { Form, Formik, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';
import {
  useEditUserMutation,
  useLogoutUserMutation,
} from '../../store/user/api';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/user/slice';
import { useAppSelector } from '../../store';
import TextField from '../TextField';
import { setSnackbarMessage } from '../../store/slices/appSlice';
import Footnote from '../Footnote/Footnote';

const userEditSchema = object({
  id: string(),
  email: string(),
  password: string().min(8),
  passwordConfirmation: string().when('password', {
    is: (password = '') => password.length > 0,
    then: string().min(8).required(),
  }),
});

export type UserEditInputType = InferType<typeof userEditSchema>;

const UserEditForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(state => state.userState.user);
  // console.log('UserEditForm: ', user);

  const [logoutUser] = useLogoutUserMutation();

  const [editUser, { isLoading, isSuccess, error, isError }] =
    useEditUserMutation();

  const formInitialValues: UserEditInputType = {
    id: user?.id || '',
    email: user?.email || '',
    password: '',
    passwordConfirmation: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: userEditSchema,
    onSubmit: (values: UserEditInputType) => {
      // console.log('---', values);
      editUser(values);
    },
  });

  useEffect(() => {
    // console.log('///+', isSuccess);
    // console.log('///-', isError);
    // console.log('///=', error);
    if (isSuccess) {
      dispatch(
        setSnackbarMessage({
          // message: 'Settings successfully saved!',
          message: 'Сохранено!',
          visible: true,
          severity: 'success',
        }),
      );
    }

    if (isError) {
      dispatch(
        setSnackbarMessage({
          message: 'Что-то пошло не так. Попробуйте позже.',
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isLoading]);

  return (
    <>
      <TextField
        id="email"
        value={formik.values.email}
        placeholder="Email"
        type="email"
        disabled
        error={false}
        fullWidth
        autoComplete="off"
      />
      <TextField
        id="password"
        placeholder="Пароль"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.password && formik.touched.password}
        fullWidth
        type="password"
        autoComplete="off"
      />
      <TextField
        id="passwordConfirmation"
        placeholder="Новый пароль"
        value={formik.values.passwordConfirmation}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          !!formik.errors.passwordConfirmation &&
          formik.touched.passwordConfirmation
        }
        fullWidth
        type="password"
        autoComplete="off"
      />
      <Spacing size={theme.spacing(2)} />
      <Button
        color="primary"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        onClick={formik.submitForm}>
        {/* Save */}Сохранить
      </Button>
      <Spacing size={theme.spacing(1)} />
      <Button variant="contained" type="button" onClick={() => navigate('/')}>
        {/* Cancel */}Назад
      </Button>
      <Spacing size={theme.spacing(4)} />
      <Button
        color="warning"
        variant="contained"
        type="button"
        onClick={() => navigate('/pricing')}>
        {/* Upgrade */}Сменить тариф
      </Button>
      <Spacing size={theme.spacing(4)} />
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          await logoutUser();
          await dispatch(logout());
          localStorage.removeItem('access_token');
          navigate('/signin');
        }}>
        <Form>
          <Button color="error" variant="contained" type="submit">
            {/* Log out */}Выйти
          </Button>
          <Spacing size={theme.spacing(2)} />
        </Form>
      </Formik>
    </>
  );
};

export default UserEditForm;
