import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { InferType, object, string } from 'yup';

import Button from '../../atoms/Button';
import Spacing from '../../atoms/Spacing';
import theme from '../../../theme';
import { useRestorePasswordMutation } from '../../../store/api/authApi';
import { TextField } from '../../index';
import { setSnackbarMessage } from '../../../store/slices/appSlice';

const validationSchema = object({
  email: string().email().required(),
});

type InputType = InferType<typeof validationSchema>;

const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues: InputType = {
    email: '',
  };

  const [restorePassword, { isLoading, isSuccess, isError }] =
    useRestorePasswordMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: InputType) => {
      restorePassword(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      navigate('/reset-password');
    }

    if (isError) {
      dispatch(
        setSnackbarMessage({
          message:
            "We're sorry, but an unhandled error occurred. Please try again later.",
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isLoading]);

  return (
    <>
      <TextField
        id="email"
        inputMode="email"
        autoFocus
        placeholder="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.email && formik.touched.email}
        fullWidth
      />
      <Spacing size={theme.spacing(1)} />
      <Button
        color="primary"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        onClick={formik.submitForm}>
        Далее{/* Send reset link */}
      </Button>
    </>
  );
};

export default ForgotPasswordForm;
