import React from 'react';

import CodeForm from '../../components/CodeForm/CodeForm';

interface Props {
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const CodePage: React.FC<Props> = ({ iframeRef }) => {
  return <CodeForm />;
};

export default CodePage;
