import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ISocial } from './types';

interface IPersonState {
  socials: ISocial[];
}

const initialState: IPersonState = {
  socials: [],
};

export const slice = createSlice({
  initialState,
  name: 'socialsSlice',
  reducers: {
    setSocials: (state, action: PayloadAction<ISocial[]>) => {
      state.socials = action.payload;
    },
  },
});

export default slice.reducer;

export const { setSocials } = slice.actions;
