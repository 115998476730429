import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
interface Props {
  body?: React.ReactNode;
}

const Wrapper = styled(Box)(`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
`);

const Inner = styled(Box)(`
    padding: 0 16px 16px;
    max-width: 450px;
    width: 100%;
`);

const EmptyLayout: React.FC<Props> = ({ body }) => {
  return (
    <Wrapper>
      <Inner>{body}</Inner>
    </Wrapper>
  );
};

export default EmptyLayout;
