import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { InferType, object, string } from 'yup';
import { FormControl, FilledInput, Link } from '@mui/material';

import { TextField } from '../index';
import theme from '../../theme';
import Spacing from '../atoms/Spacing';
import Button from '../atoms/Button';
import Footnote from '../Footnote/Footnote';
import { useLoginUserMutation } from '../../store/api/authApi';
import { setSnackbarMessage } from '../../store/slices/appSlice';

const loginValuesSchema = object({
  email: string().email().required(),
  password: string().min(8).required(),
});

type LoginValues = InferType<typeof loginValuesSchema>;
const SignInWithEmail: React.FC = () => {
  const dispatch = useDispatch();

  const formInitialValues: LoginValues = { email: '', password: '' };

  const [loginUser, { isLoading, isSuccess, error, isError }] =
    useLoginUserMutation();

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: loginValuesSchema,
    onSubmit: (values: LoginValues) => {
      loginUser(values);
    },
  });

  // We're sorry, but an unhandled error occurred. Please try again later.
  useEffect(() => {
    if (isError) {
      dispatch(
        setSnackbarMessage({
          message: 'Возникла ошибка! Попробуйте позже...',
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isLoading]);

  return (
    <>
      <TextField
        id="email"
        placeholder="Email"
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.email && formik.touched.email}
        fullWidth
        autoComplete="email"
      />
      <FormControl sx={{ mb: 1, width: '100%' }} variant="outlined">
        <FilledInput
          id="password"
          placeholder="Пароль"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.password && formik.touched.password}
          autoComplete="current-password"
          fullWidth
        />
      </FormControl>
      <Footnote sx={{ textAlign: 'right', padding: 0 }}>
        <Link href="/forgot-password">
          {/* Forgot password? */}Забыли пароль?
        </Link>
      </Footnote>
      <Spacing size={theme.spacing(2)} />
      <Button
        color="primary"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        onClick={formik.submitForm}>
        {/* Sign in */}Войти
      </Button>
      <Spacing size={theme.spacing(3)} />
      <Footnote sx={{ textAlign: 'center' }}>
        {/* Don’t have an account yet? */}Еще нет аккаунта?{` `}
        <Link href="/signup">{/* Sign up */}Зарегистрируйтесь</Link>.
      </Footnote>
    </>
  );
};

export default SignInWithEmail;
