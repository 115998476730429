import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../../store';

const GuestOnlyRoute = ({ element }: { element: JSX.Element }) => {
  const user = useAppSelector(state => state.userState.user);

  if (user) {
    return <Navigate to="/" replace />;
  }

  return element;
};

export default GuestOnlyRoute;
