import i18next, { ThirdPartyModule } from 'i18next';

import en from './translations/en.json';
import ru from './translations/ru.json';

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
};

export const init = (thirdPartyModule: ThirdPartyModule) => {
  i18next.use(thirdPartyModule).init({
    compatibilityJSON: 'v3',
    resources,
    fallbackLng: 'ru',
  });
};
