import React, { useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { Icon16AddCircleOutline, Icon16Sync } from '@vkontakte/icons';

import { Wrapper, IframeWrapper, Top, Bottom } from './Preview.styled';
import { useAppSelector } from '../../store';
import TopHeaderBar from '../TopHeaderBar/TopHeaderBar';
import reloadIframe from '../../utils/helpers/reloadIframe';
import { useSyncMySiteMutation } from '../../store/site/api';
import { setSnackbarMessage } from '../../store/slices/appSlice';
import { useDispatch } from 'react-redux';

// http://localhost:3000/notion-get-token?code=038eef6d-80eb-4044-849b-2204e41e6096&state=

const redirectUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://api.notion.com/v1/oauth/authorize?client_id=0bdd82e4-67a8-4a5b-adba-cc84c39d6d80&response_type=code&owner=user&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fnotion-get-token'
    : 'https://api.notion.com/v1/oauth/authorize?client_id=eab7b6b4-8c3e-4000-aa32-929945acdaa8&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.xeor.ru%2Fnotion-get-token';

interface Props {
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const Preview: React.FC<Props> = ({ iframeRef }) => {
  const dispatch = useDispatch();
  const site = useAppSelector(state => state.siteState.site);
  const userHasSite = site && site.sid !== '';
  const siteIsOk = site && site.slug !== '' && site.notionPageUrl !== '';
  const [syncMySite, { isLoading: isSyncing, error, isError }] =
    useSyncMySiteMutation();
  const outOfSync = useAppSelector(state => state.appState.outOfSync);

  useEffect(() => {
    if (isError) {
      // @ts-ignore
      const errorMessage = error?.data?.message ?? '';
      dispatch(
        setSnackbarMessage({
          message: errorMessage || 'Что-то пошло не так. Попробуйте позже.',
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isSyncing]);

  // 'https://api.notion.com/v1/oauth/authorize?client_id=eab7b6b4-8c3e-4000-aa32-929945acdaa8&response_type=code&owner=user&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fnotion-get-token';

  return (
    <>
      <Top>
        <TopHeaderBar
          isSyncing={isSyncing.toString()}
          onReloadIframe={async () => {
            await syncMySite(null);
            reloadIframe(iframeRef);
          }}
        />
      </Top>
      <Bottom>
        <Wrapper>
          <Paper
            sx={{
              // position: 'absolute',
              // top: 8,
              // left: 16,
              boxShadow: 'none',
              background: '#fff',
              borderRadius: '50%',
              width: 32,
              height: 32,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            elevation={1}>
            {userHasSite && siteIsOk && !outOfSync && (
              <IframeWrapper>
                <iframe
                  ref={iframeRef}
                  src={
                    process.env.NODE_ENV === 'development'
                      ? `http://xeor.dd/${site.slug}/`
                      : `https://xeor.ru/${site.slug}/`
                  }
                />
              </IframeWrapper>
            )}
            {userHasSite && !siteIsOk && !outOfSync && (
              <Box
                sx={{
                  color: '#1a1a1a',
                  textDecoration: 'none',
                  textAlign: 'center',
                }}>
                <Typography variant="body1" sx={{ margin: '0 !important' }}>
                  Укажите&nbsp;<strong>Адрес&nbsp;сайта</strong>&nbsp;и
                </Typography>
                <Typography variant="body1">
                  <strong>Notion&nbsp;Page&nbsp;URL</strong>
                </Typography>
              </Box>
            )}
            {!userHasSite && !outOfSync && (
              <Box
                onClick={() => {
                  window.location.href = redirectUrl;
                }}
                sx={{
                  display: 'flex',
                  color: '#1a1a1a',
                  textDecoration: 'none',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}>
                <Icon16AddCircleOutline />
                <span>&nbsp;Создать&nbsp;сайт</span>
              </Box>
            )}
            {userHasSite && siteIsOk && outOfSync && (
              <Box
                onClick={() => {
                  window.location.href = redirectUrl;
                }}
                sx={{
                  display: 'flex',
                  color: '#1a1a1a',
                  textDecoration: 'none',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}>
                <Icon16Sync />
                <span>&nbsp;Обновить&nbsp;интеграцию</span>
              </Box>
            )}
          </Paper>
        </Wrapper>
      </Bottom>
    </>
  );
};

export default Preview;
