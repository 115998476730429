import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  height: 100%;
`;

export const IframeWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  z-index: 25;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const Top = styled(Box)`
  height: 48px;
  //border-bottom: 2px solid #f6f7f8;
  position: relative;
  background-color: #f6f7f8;
  //&:before {
  //  content: ' ';
  //  display: block;
  //  position: absolute;
  //  left: -2px;
  //  top: 0;
  //  height: 56px;
  //  width: 2px;
  //  background-color: #fff;
  //}
`;

export const Bottom = styled(Box)`
  //border-left: 2px solid #f6f7f8;
  //border-top: 2px solid #f6f7f8;
  border-top-left-radius: 8px;
  flex: 1;
  background: #fff;
  position: relative;
  overflow: hidden;
`;
