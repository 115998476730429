import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Button as ButtonBase,
  ButtonProps as ButtonBaseProps,
} from '@mui/material';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

const PREFIX = 'Button';

const classes = {
  container: `${PREFIX}-container`,
};

// const primaryButton = ` linear-gradient(165.57deg,#bcf7ff,#acffdd)`;
const primaryButton = '#1a1a1a';
// const secondaryButton = `rgba(34, 34, 34, 0.05)`;
// const secondaryButton = '#f4f4f4';
// const secondaryButton = '#fff';
const secondaryButton = 'rgba(255, 255, 255, 0)';
const cancelButton = '#f44336';
// color: #222222;
const StyledButton = styled(ButtonBase)<ButtonBaseProps>(
  ({ color, theme }) => `
    width: 100%;
    box-shadow: none !important;
    border: none;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 16px;
    color: ${color === 'secondary' ? '#1a1a1a' : '#fff'} !important;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    position: relative;
    background: ${secondaryButton} !important;
    ${(color === 'primary' && `background: ${primaryButton} !important;`) || ''}
    ${(color === 'error' && `background: ${cancelButton} !important;`) || ''}
    ${(color === 'warning' && `background: #ffbe3d !important;`) || ''}
    &[disabled] {
      opacity: .7
    }
  `,
);
const StyledLoadingButton = styled(LoadingButton)<LoadingButtonProps>(
  ({ color, loading, theme }) => `
    width: 100%;
    box-shadow: none !important;
    border: none;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 16px;
    color: ${color === 'secondary' ? '#1a1a1a' : '#fff'} !important;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    position: relative;
    text-indent: ${loading ? '-9999px' : 'none'};
    background: ${secondaryButton} !important;
    ${(color === 'primary' && `background: ${primaryButton} !important;`) || ''}
    ${(color === 'error' && `background: ${cancelButton} !important;`) || ''}
    ${(color === 'warning' && `background: #ffbe3d !important;`) || ''}
    span {
      color: #fff;
    }
    &[disabled] {
      opacity: .7
    }
  `,
);
export interface IProps extends LoadingButtonProps {
  string?: string;
}
const Button: React.FC<IProps> = ({
  color = 'secondary',
  children,
  loading,
  ...props
}) => {
  if (loading === undefined) {
    return (
      <StyledButton color={color} className={classes.container} {...props}>
        {children}
      </StyledButton>
    );
  }
  return (
    <StyledLoadingButton
      color={color}
      className={classes.container}
      {...{ loading }}
      {...props}>
      {children}
    </StyledLoadingButton>
  );
};

export default Button;
