import React from 'react';

import LeftNavDock from '../../components/LeftNavDock/LeftNavDock';
import { Wrapper, Left, Right, LeftInner } from './TwoColumnsLayout.styled';

interface Props {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const TwoColumnsLayout: React.FC<Props> = ({ left, right }) => {
  return (
    <Wrapper>
      <Left>
        <>
          <LeftNavDock />
          <LeftInner>{left}</LeftInner>
        </>
      </Left>
      <Right>{right}</Right>
    </Wrapper>
  );
};

export default TwoColumnsLayout;
