import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  BoxProps,
  TextField as TextFieldBase,
  TextFieldProps as TextFieldBaseProps,
} from '@mui/material';

const PREFIX = 'TextField';

const classes = {
  container: `${PREFIX}-container`,
};
const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  [`&.${classes.container}`]: {
    marginBottom: theme.spacing(1),
  },
  [`&.${classes.container} .input-left .MuiInputBase-root`]: {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    width: '122px',
  },
  [`&.${classes.container} .input-right .MuiInputBase-root`]: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  [`&.${classes.container} .input-left input`]: {
    paddingRight: 0,
  },
  [`&.${classes.container} .input-right input`]: {
    paddingLeft: 0,
  },
}));
const StyledTextField = styled(TextFieldBase)<TextFieldBaseProps>(
  ({ theme }) => ({}),
);

export type IProps = TextFieldBaseProps;
const TextField: React.FC<IProps> = ({ ...props }) => {
  return (
    <StyledContainer className={classes.container}>
      <StyledTextField error={true} variant="filled" {...props} />
    </StyledContainer>
  );
};

export default TextField;
