import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useLoginUserWithGoogleMutation } from '../../store/api/authApi';

const Wrapper = styled(Box)`
  div,
  iframe {
    //width: 100% !important;
    margin: 0 auto !important;
  }
`;

const SignInWithSocial: React.FC = () => {
  const [width, setWidth] = useState(0);
  const refGoogleButton = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginUserWithGoogle, { isLoading, isSuccess, isError }] =
    useLoginUserWithGoogleMutation();

  const myHandlerFunction = (event: UIEvent) => {
    setWidth(refGoogleButton?.current?.clientWidth || 400);
  };
  const handleCallbackResponse = async (response: any) => {
    // console.log(response);
    const { credential = '' } = response;
    loginUserWithGoogle({ credential });
  };

  useEffect(() => {
    // if (isSuccess) {
    //   navigate('/');
    // }
    if (isError) {
      window.location.reload();
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    window.addEventListener('resize', myHandlerFunction);
    // @ts-ignore
    if (google) {
      // @ts-ignore
      // console.log(google);
      // @ts-ignore
      google.accounts.id.initialize({
        client_id:
          '535262046562-unak0i32adtu88i661ie21abkm933qp2.apps.googleusercontent.com',
        client_secret: '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',
        callback: handleCallbackResponse,
      });

      setWidth(refGoogleButton?.current?.clientWidth || 480);
    }
    return () => {
      window.removeEventListener('resize', myHandlerFunction);
    };
  }, []);
  useEffect(() => {
    if (width) {
      // @ts-ignore
      google.accounts.id.renderButton(refGoogleButton.current, {
        type: 'icon',
        // theme: 'light',
        // shape: 'square',
        // width: '100%',
        context: 'signin',
        logo_alignment: 'center',
      });
    }
  }, [width]);
  return <Wrapper ref={refGoogleButton} id="g_id_onload" />;
};

export default SignInWithSocial;
