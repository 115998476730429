import { createSlice } from '@reduxjs/toolkit';
import { isRejected } from '../common/matchers';
import { BaseSliceState } from '../common/types';

export interface SliceState extends BaseSliceState {
  token?: string;
}

const initialState: SliceState = {
  token: '',
  isErrored: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(isRejected(authSlice.name), (state, action) => {
      return {
        ...state,
        isErrored: true,
        error: action.payload,
      };
    });
  },
});

export default authSlice;
