import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Backdrop } from '@mui/material';

import { useCreateMySiteQuery } from '../../store/site/api';
import CircularProgress from '@mui/material/CircularProgress';
import { setSnackbarMessage } from '../../store/slices/appSlice';
import { useDispatch } from 'react-redux';

// http://localhost:3000/notion-get-token?code=038eef6d-80eb-4044-849b-2204e41e6096&state=
const NotionAuth: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') ?? '';
  const { isSuccess, isLoading, isError } = useCreateMySiteQuery({
    code,
  });

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }

    if (isError) {
      dispatch(
        setSnackbarMessage({
          message: 'Что-то пошло не так. Попробуйте позже.',
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isLoading]);

  return (
    <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default NotionAuth;
