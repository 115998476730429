import { useAppSelector } from '../../store';

export default function hasSite(): boolean {
  const site = useAppSelector(state => state.siteState.site);

  if (site === null) {
    return false;
  }

  return site.sid !== '' && site.slug !== '' && site.notionPageUrl !== '';
}
