import React, { useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { initReactI18next } from 'react-i18next';
import { init } from './i18n';
import { store } from './store';
import { Navigation } from './navigation';
import {
  BottomNavigation,
  BottomNavigationAction,
  CssBaseline,
  Paper,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import theme from './theme';

// import RestoreIcon from '@mui/icons-material/Restore';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ArchiveIcon from '@mui/icons-material/Archive';
// import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
// import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

init(initReactI18next);

const App = () => {
  const [value, setValue] = useState(0);

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Navigation />
          {/* <Paper
            sx={{
              position: 'fixed',
              bottom: 16,
              left: 16,
              right: 16,
              boxShadow: 'none',
              color: '#fff',
            }}
            elevation={3}>
            <BottomNavigation
              sx={{
                color: '#fff',
                backgroundColor: '#1a1a1a',
                borderRadius: 16,
              }}
              showLabels={false}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}>
              <BottomNavigationAction icon={<BarChartRoundedIcon />} />
              <BottomNavigationAction icon={<QrCode2RoundedIcon />} />
              <BottomNavigationAction icon={<SettingsRoundedIcon />} />
            </BottomNavigation>
          </Paper> */}
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
