import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { App, SnackbarMessage } from './types';

const initialState: App = {
  isLoaded: false,
  snackbarMessage: {
    message: '',
    visible: false,
    severity: 'info',
  },
  outOfSync: false, // нужно обновить права в notion
};

export const index = createSlice({
  initialState,
  name: 'appSlice',
  reducers: {
    setSnackbarMessage: (state, action: PayloadAction<SnackbarMessage>) => {
      state.snackbarMessage = action.payload;
    },
    clearSnackbarMessage: state => {
      state.snackbarMessage.visible = false;
    },
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    setOutOfSync: (state, action: PayloadAction<boolean>) => {
      state.outOfSync = action.payload;
    },
  },
});

export default index.reducer;

export const {
  setSnackbarMessage,
  clearSnackbarMessage,
  setIsLoaded,
  setOutOfSync,
} = index.actions;
