import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)``;

export const List = styled(Box)`
  // margin-left: ${({ theme }) => `-${theme.spacing(2)}`};
  // margin-right: ${({ theme }) => `-${theme.spacing(2)}`};
`;

interface ThemeProps {
  selected?: boolean;
}

export const Theme = styled(Box)<ThemeProps>`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  opacity: ${({ selected }) => (selected ? '1' : '.5')};
  border-radius: 16px;
  overflow: hidden;
`;

export const Image = styled(Box)`
  //border-radius: 2px;
  // margin-bottom: ${({ theme }) => theme.spacing(3)};
  overflow: hidden;
  position: relative;
  padding-top: 50%;
  //&:after {
  //  content: '';
  //  display: block;
  //  width: 100%;
  //  height: 10%;
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  z-index: 2;
  //  background: -moz-linear-gradient(
  //    top,
  //    rgba(255, 255, 255, 0) 0%,
  //    rgba(246, 247, 248, 1) 100%
  //  );
  //  background: -webkit-linear-gradient(
  //    top,
  //    rgba(255, 255, 255, 0) 0%,
  //    rgba(246, 247, 248, 1) 100%
  //  );
  //  background: linear-gradient(
  //    to bottom,
  //    rgba(255, 255, 255, 0) 0%,
  //    rgba(246, 247, 248, 1) 100%
  //  );
  //  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f6f7f8',GradientType=0 );
  //}
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 1;
  }
`;

interface ThemeProps {
  selected?: boolean;
}

export const Title = styled(Box)<ThemeProps>`
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  opacity: ${({ selected }) => (selected ? '1' : '.3')};
`;
