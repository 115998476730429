import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { userApi } from '../../user/api';
import { siteApi } from '../../site/api';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { setToken } from '../../user/slice';
import getBaseUrl from '../../../utils/helpers/getBaseUrl';

const baseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl('auth'),
});

const signUpQuery: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    console.log('====', result);
    // capture your error here
  }
  return result;
};

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: signUpQuery,
  endpoints: builder => ({
    registerUser: builder.mutation<
      { token: string },
      { email: string; password: string }
    >({
      query(data) {
        return {
          url: 'register',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { token = '' },
          } = await queryFulfilled;
          localStorage.setItem('access_token', token);
          await dispatch(setToken(token));
          await dispatch(
            userApi.endpoints.getMe.initiate(null, { forceRefetch: true }),
          );
        } catch (error) {}
      },
    }),
    loginUser: builder.mutation<
      { token: string },
      { email: string; password: string }
    >({
      query(data) {
        return {
          url: 'login',
          method: 'POST',
          body: data,
          // credentials: 'include',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { token = '' },
          } = await queryFulfilled;
          localStorage.setItem('access_token', token);
          // save token
          await dispatch(setToken(token));

          // receive info about auth user
          await dispatch(
            userApi.endpoints.getMe.initiate(null, { forceRefetch: true }),
          );
          // receive info about site
          await dispatch(
            siteApi.endpoints.getMySite.initiate(null, { forceRefetch: true }),
          );
        } catch (error) {}
      },
    }),
    loginUserWithGoogle: builder.mutation<
      { token: string },
      { credential: string }
    >({
      query(data) {
        return {
          url: 'google/callback', // worked auth
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          // get jwt token from backend
          const {
            data: { token = '' },
          } = await queryFulfilled;

          // save token
          localStorage.setItem('access_token', token);
          await dispatch(setToken(token));

          // receive info about auth user from backend
          await dispatch(
            userApi.endpoints.getMe.initiate(null, { forceRefetch: true }),
          );
        } catch (error) {
          //
        }
      },
    }),
    restorePassword: builder.mutation<{ status: string }, { email: string }>({
      query(data) {
        return {
          url: 'restore-password',
          method: 'POST',
          body: data,
        };
      },
    }),
    resetPassword: builder.mutation<
      { token: string },
      { code: string; password: string }
    >({
      query(data) {
        return {
          url: 'reset-password',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { token = '' },
          } = await queryFulfilled;
          localStorage.setItem('access_token', token);
          // save token
          await dispatch(setToken(token));

          // receive info about auth user
          await dispatch(
            userApi.endpoints.getMe.initiate(null, { forceRefetch: true }),
          );
        } catch (error) {}
      },
    }),
    // verifyEmail: builder.mutation<
    //   IGenericResponse,
    //   { verificationCode: string }
    // >({
    //   query({ verificationCode }) {
    //     return {
    //       url: `verifyemail/${verificationCode}`,
    //       method: 'GET',
    //     };
    //   },
    // }),
  }),
});

export const {
  useLoginUserMutation,
  useLoginUserWithGoogleMutation,
  useRegisterUserMutation,
  useRestorePasswordMutation,
  useResetPasswordMutation,
  // useVerifyEmailMutation,
} = authApi;
