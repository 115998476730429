import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { InferType, object, string } from 'yup';
import { FormControl, Link, FilledInput } from '@mui/material';
import { useDispatch } from 'react-redux';

import Button from '../Button/Button';
import Spacing from '../Spacing/Spacing';
import TextField from '../TextField';
import theme from '../../theme';
import Footnote from '../Footnote/Footnote';
import { useRegisterUserMutation } from '../../store/api/authApi';
import { setSnackbarMessage } from '../../store/slices/appSlice';

const schema = object({
  email: string().email().required(),
  password: string().min(8).required(),
});

type InputType = InferType<typeof schema>;

const SignUpForm: React.FC = () => {
  const dispatch = useDispatch();

  const formInitialValues: InputType = {
    email: '',
    password: '',
  };

  const [registerUser, { isLoading, isError }] = useRegisterUserMutation();

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: schema,
    onSubmit: (values: InputType) => {
      registerUser(values);
    },
  });

  useEffect(() => {
    if (isError) {
      dispatch(
        setSnackbarMessage({
          message: 'Что-то пошло не так. Попробуйте позже.',
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isLoading]);

  return (
    <>
      <TextField
        id="email"
        placeholder="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.email && formik.touched.email}
        // className={formik.errors.email && formik.touched.email ? 'error' : ''}
        fullWidth
      />
      <FormControl sx={{ mb: 1, width: '100%' }} variant="outlined">
        <FilledInput
          id="password"
          placeholder="Пароль"
          type={'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.password && formik.touched.password}
          fullWidth
        />
      </FormControl>
      <Spacing size={theme.spacing(1)} />
      <Button
        color="primary"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        onClick={formik.submitForm}>
        Зарегистрироваться{/* Get started */}
      </Button>
      <Spacing size={theme.spacing(7)} />
      <Footnote sx={{ textAlign: 'center' }}>
        Уже есть аккаунт{/* Already have an account */}?{' '}
        <Link href="/signin">Войти{/* Sign in */}</Link>.
      </Footnote>
    </>
  );
};

export default SignUpForm;
