/**
 * Returns the CMS theme and color scheme.
 *
 * @param {string} code
 */
export default function getThemeAndColor(code = ''): string[] {
  if (!code) {
    return ['basic', 'default'];
  }

  return code.split('_');
}
