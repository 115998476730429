import React, { useEffect } from 'react';
import { InferType, object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { useUpdateMySiteMutation } from '../../store/site/api';
import { useAppSelector } from '../../store';
import { setSnackbarMessage, setOutOfSync } from '../../store/slices/appSlice';
import reloadIframe from '../../utils/helpers/reloadIframe';
import Button from '../Button/Button';
import Spacing from '../Spacing/Spacing';
import TextField from '../TextField';
import theme from '../../theme';

import { Wrapper } from './BuilderForm.styled';

const schema = object({
  sid: string(),
  title: string().required(),
  description: string().required(),
  theme: string(),
  notionPageUrl: string().required(),
  slug: string().required(),
});

export type InputType = InferType<typeof schema>;

interface IProps {
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const BuilderForm: React.FC<IProps> = ({ iframeRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const site = useAppSelector(state => state.siteState.site);
  const userHasSite = site?.sid !== '';

  const [updateMySite, { isLoading, isSuccess, error, isError }] =
    useUpdateMySiteMutation();

  const formInitialValues: InputType = {
    sid: site?.sid || '',
    title: site?.title || '', // My Awesome Site | Мой новый сайт
    description: site?.description || '', // Just another XEOR site | Еще один сайт на xeor.ru
    theme: site?.theme || '',
    notionPageUrl: site?.notionPageUrl || '',
    slug: site?.slug || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: schema,
    onSubmit: (values: InputType) => {
      updateMySite(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      reloadIframe(iframeRef);
      dispatch(
        setSnackbarMessage({
          message: 'Сохранено!',
          visible: true,
          severity: 'success',
        }),
      );
    }

    if (isError) {
      // @ts-ignore
      const errorCode = error?.data?.error ?? '';
      if (errorCode === 'notion_sync_error') {
        dispatch(setOutOfSync(true));
      } else {
        // @ts-ignore
        const errorMessage = error?.data?.message ?? '';
        dispatch(
          setSnackbarMessage({
            message: errorMessage || 'Что-то пошло не так. Попробуйте позже.',
            visible: true,
            severity: 'error',
          }),
        );
      }
    }
  }, [isLoading]);

  return (
    <Wrapper sx={{ opacity: userHasSite ? 1 : '.5' }}>
      <TextField
        label="Заголовок"
        placeholder=""
        id="title"
        value={formik.values.title}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.title && formik.touched.title}
        fullWidth
        autoComplete="off"
        disabled={!userHasSite}
      />
      <TextField
        label="Описание"
        id="description"
        placeholder=""
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.description && formik.touched.description}
        fullWidth
        multiline
        minRows={2}
        disabled={!userHasSite}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
        }}>
        <TextField
          label="Адрес сайта"
          value="https://xeor.ru/"
          fullWidth
          disabled
          className="input-left"
        />
        <TextField
          placeholder="slug"
          className="input-right"
          id="slug"
          value={formik.values.slug}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.slug && formik.touched.slug}
          fullWidth
          autoComplete="off"
          disabled={!userHasSite}
        />
      </Box>
      <TextField
        label="Notion Page URL"
        placeholder="https://notion.so/a92304c267f4439692a0f4c988d89fa9"
        id="notionPageUrl"
        value={formik.values.notionPageUrl}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.notionPageUrl && formik.touched.notionPageUrl}
        fullWidth
        autoComplete="off"
        disabled={!userHasSite}
        InputLabelProps={{ shrink: true }}
      />
      <Spacing size={theme.spacing(2)} />
      <Button
        color="primary"
        variant="contained"
        loading={isLoading}
        disabled={!userHasSite || isLoading}
        onClick={formik.submitForm}>
        Сохранить
      </Button>
      {/* {userHasSite && (
        <>
          <Spacing size={theme.spacing(2)} />
          <Button
            variant="contained"
            type="button"
            onClick={() => navigate('/themes')}>
            Выбрать тему
          </Button>
        </>
      )} */}
    </Wrapper>
  );
};

export default BuilderForm;
