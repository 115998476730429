import React from 'react';
import Divider from './Divider';
import SignInWithEmail from './SignInWithEmail';
import SignInWithSocial from './SignInWithSocial';

export interface IProps {
  children?: React.ReactNode;
}
const SignIn: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <SignInWithEmail />
      <Divider />
      <SignInWithSocial />
    </>
  );
};

export default SignIn;
