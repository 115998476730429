import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getBaseUrl from '../../utils/helpers/getBaseUrl';
import { RootState } from '../index';
import { IPayment } from './types';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl('p'),
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).userState.token ||
        localStorage.getItem('access_token');

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Payments'],
  endpoints: builder => ({
    paymentsStore: builder.mutation<IPayment, Partial<IPayment>>({
      query(body) {
        return {
          url: '',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { usePaymentsStoreMutation } = paymentsApi;
