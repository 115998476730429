import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setCode } from './slice';
import type { ICode } from './types';
import getBaseUrl from '../../utils/helpers/getBaseUrl';
import { RootState } from '../index';

export const codeApi = createApi({
  reducerPath: 'codeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl('code'),
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).userState.token ||
        localStorage.getItem('access_token');

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Code'],
  endpoints: builder => ({
    code: builder.query<ICode, null>({
      query: () => '',
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCode(data));
        } catch (error) {
          // console.error(error);
        }
      },
    }),
    storeCode: builder.mutation<ICode, ICode>({
      query: body => ({
        url: '',
        method: 'POST',
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCode(data));
        } catch (error) {}
      },
    }),
    getCode: builder.mutation<ICode, void>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCode(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useCodeQuery, useGetCodeMutation, useStoreCodeMutation } =
  codeApi;
