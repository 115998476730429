import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ITheme } from './types';

interface IThemesState {
  themes: ITheme[] | null;
  selectedTheme?: string;
}

const initialState: IThemesState = {
  themes: null,
  selectedTheme: undefined,
};

export const slice = createSlice({
  initialState,
  name: 'themesSlice',
  reducers: {
    setThemes: (state, action: PayloadAction<ITheme[]>) => {
      state.themes = action.payload;
    },
    selectTheme: (state, action: PayloadAction<string | undefined>) => {
      state.selectedTheme = action.payload;
    },
  },
});

export default slice.reducer;

export const { setThemes, selectTheme } = slice.actions;
