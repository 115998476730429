import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ISite } from './types';

interface ISiteState {
  site: ISite | null;
}

const initialState: ISiteState = {
  site: null,
};

export const slice = createSlice({
  initialState,
  name: 'siteSlice',
  reducers: {
    setSite: (state, action: PayloadAction<ISite>) => {
      state.site = action.payload;
    },
  },
});

export default slice.reducer;

export const { setSite } = slice.actions;
