import React from 'react';

import SocialsForm from '../../components/SocialsForm/SocialsForm';

interface Props {
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const SocialsPage: React.FC<Props> = ({ iframeRef }) => {
  return <SocialsForm />;
};

export default SocialsPage;
