import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import theme from '../../theme';
import { useAppSelector } from '../../store';
import { setSnackbarMessage } from '../../store/slices/appSlice';
import Button from '../Button/Button';
import Spacing from '../Spacing/Spacing';
import { Wrapper, List, Theme, Image, Title } from './ThemeList.styled';
import {
  useGetThemesQuery,
  useChangeThemeMutation,
} from '../../store/themes/api';
import { selectTheme } from '../../store/themes/slice';
import reloadIframe from '../../utils/helpers/reloadIframe';
import getThemeAndColor from '../../utils/helpers/getThemeAndColor';
import hasSite from '../../utils/helpers/hasSite';

interface Props {
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const ThemeList: React.FC<Props> = ({ iframeRef }) => {
  const dispatch = useDispatch();

  const site = useAppSelector(state => state.siteState.site);
  const selectedTheme = useAppSelector(
    state => state.themesState.selectedTheme,
  );
  const userHasSite = hasSite();

  const { data: themes, isLoading } = useGetThemesQuery(null);

  const [changeTheme, { isLoading: isChanging, isSuccess, error, isError }] =
    useChangeThemeMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setSnackbarMessage({
          message: 'Сохранено!',
          visible: true,
          severity: 'success',
        }),
      );
    }

    if (isError) {
      // @ts-ignore
      const errorMessage = error?.data?.message ?? '';
      dispatch(
        setSnackbarMessage({
          message: errorMessage || 'Что-то пошло не так. Попробуйте еще раз.',
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isChanging]);

  // formik.submitForm
  return (
    <Wrapper>
      <List>
        {themes &&
          themes.map(({ name, code, thumbnail }) => {
            const themeCode = `${site?.theme}_${site?.color}`;
            const isSelected =
              selectedTheme === code || (!selectedTheme && themeCode === code);
            return (
              <Theme
                key={code}
                selected={isSelected}
                onClick={() => {
                  dispatch(selectTheme(code));
                  const [theme, color] = getThemeAndColor(code);
                  reloadIframe(iframeRef, { preview: 1, theme, color });
                }}>
                <Image>
                  <img src={thumbnail} alt={name} />
                </Image>
              </Theme>
            );
          })}
      </List>
      <Spacing size={theme.spacing(2)} />
      <Button
        color="primary"
        variant="contained"
        loading={isLoading}
        onClick={() => {
          changeTheme({ theme: selectedTheme, sid: site?.sid });
        }}
        disabled={isLoading || !userHasSite}>
        Сохранить
      </Button>
    </Wrapper>
  );
};

export default ThemeList;
