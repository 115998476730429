import { AnyAction } from '@reduxjs/toolkit';
import { UnknownAsyncThunkRejectedAction } from '@reduxjs/toolkit/dist/matchers';
import { RootState } from '..';

export const isRejected = (storeName: keyof RootState) => {
  return (action: AnyAction): action is UnknownAsyncThunkRejectedAction => {
    return (
      (action.type as string).startsWith(`${storeName as string}/`) &&
      (action.type as string).endsWith('/rejected')
    );
  };
};

export const isPending = (storeName: keyof RootState) => {
  return (action: AnyAction): action is UnknownAsyncThunkRejectedAction => {
    return (
      (action.type as string).startsWith(`${storeName as string}/`) &&
      (action.type as string).endsWith('/pending')
    );
  };
};

export const isFulfilled = (storeName: keyof RootState) => {
  return (action: AnyAction): action is UnknownAsyncThunkRejectedAction => {
    return (
      (action.type as string).startsWith(`${storeName as string}/`) &&
      (action.type as string).endsWith('/pending')
    );
  };
};
