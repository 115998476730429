import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { rtkQueryErrorLogger } from '../middleware/rtkQueryErrorLogger';

import authSlice from './slices/authSlice';
import { authApi } from './api/authApi';
import { codeApi } from './code/api';
import { personApi } from './person/api';
import { userApi } from './user/api';
import { themesApi } from './themes/api';
import { siteApi } from './site/api';
import { socialsApi } from './socials/api';
import { paymentsApi } from './payments/api';

import appReducer from './slices/appSlice';
import codeReducer from './code/slice';
import userReducer from './user/slice';
import personReducer from './person/slice';
import siteReducer from './site/slice';
import socialsReducer from './socials/slice';
import themesReducer from './themes/slice';
import { testApi } from './api/testApi';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [codeApi.reducerPath]: codeApi.reducer,
  [personApi.reducerPath]: personApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [siteApi.reducerPath]: siteApi.reducer,
  [socialsApi.reducerPath]: socialsApi.reducer,
  [themesApi.reducerPath]: themesApi.reducer,
  [testApi.reducerPath]: testApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  appState: appReducer,
  codeState: codeReducer,
  personState: personReducer,
  siteState: siteReducer,
  themesState: themesReducer,
  userState: userReducer,
  socialsState: socialsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({}).concat([
      rtkQueryErrorLogger,
      authApi.middleware,
      codeApi.middleware,
      personApi.middleware,
      userApi.middleware,
      siteApi.middleware,
      themesApi.middleware,
      testApi.middleware,
      paymentsApi.middleware,
      socialsApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type ThunkAPI = {
  dispatch: AppDispatch;
  state: RootState;
};

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
