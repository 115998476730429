import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
  size: number | string;
}

const Wrapper = styled(Box)<Props>(({ size }) => ({
  height: typeof size === 'number' ? `${size}px` : size,
}));

const Spacing: React.FC<Props> = ({ size }) => {
  return <Wrapper size={size} />;
};

export default Spacing;
