import React from 'react';

import { BuilderForm } from '../../components';

interface IProps {
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

const BuilderPage: React.FC<IProps> = ({ iframeRef }) => {
  return <BuilderForm iframeRef={iframeRef} />;
};

export default BuilderPage;
