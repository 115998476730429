import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { TestType } from './types';
import getBaseUrl from '../../../utils/helpers/getBaseUrl';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

export const testApi = createApi({
  reducerPath: 'testApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  tagTypes: ['Test'],
  endpoints: builder => ({
    test: builder.query<TestType, null>({
      query() {
        return {
          url: 'test2',
          // credentials: 'include',
        };
      },
      transformResponse: (result: { data: { test: TestType } }) =>
        result.data.test,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
          // dispatch(setUser(data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const { useTestQuery } = testApi;
