import { Box, Link, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)`
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
`;

export const Button = styled(Paper)`
  box-shadow: none;
  background: none;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
  border-radius: 0;
`;

export const Title = styled(Box)`
  font-size: 8px;
  padding-top: 4px;
`;

export const Sections = styled(Box)`
  & > div {
    height: 56px;
    margin: 0;
  }
`;

export const IconWrapper = styled(Link)`
  display: block;
  color: #1a1a1a;
  text-align: center;
  text-decoration: none;
  & > div {
    display: block;
    margin: 0 auto;
  }
`;
