import React from 'react';
import { Icon24User, Icon24RefreshOutline } from '@vkontakte/icons';

import { Wrapper, IconButton, IconWrapper } from './TopHeaderBar.styled';
import hasSite from '../../utils/helpers/hasSite';

interface Props {
  isSyncing: string;
  onReloadIframe: () => void;
}
const TopHeaderBar: React.FC<Props> = ({
  onReloadIframe,
  isSyncing = false,
}) => {
  return (
    <Wrapper>
      {hasSite() && (
        <IconWrapper>
          <IconButton
            syncing={isSyncing.toString()}
            onClick={() => {
              if (isSyncing.toString() === 'true') {
                return;
              }

              onReloadIframe();
            }}
            sx={{ display: 'block', color: '#1a1a1a' }}
            title="Синхронизировать содержимое страницы">
            <Icon24RefreshOutline />
          </IconButton>
        </IconWrapper>
      )}
    </Wrapper>
  );
};

export default TopHeaderBar;
