import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex;
`;

export const Left = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  width: 348px;
  //border-right: 2px solid #f6f7f8;
`;

export const LeftInner = styled(Box)`
  margin-left: 48px;
  padding: ${({ theme }) =>
    `${theme.spacing(6)} ${theme.spacing(2)} ${theme.spacing(2)}`};
  background-color: #f6f7f8;
  width: 300px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Right = styled(Box)`
  transition: left 0.3s cubic-bezier(0, 0, 0, 1);
  position: absolute;
  top: 0;
  right: 0;
  left: 348px;
  bottom: 0;
  overflow: hidden;
  z-index: 100;
  display: flex;
  flex-direction: column;
`;
