import React, { useEffect, useState } from 'react';
import { InferType, object, string } from 'yup';
import { useFormik } from 'formik';

import Button from '../atoms/Button';
import Spacing from '../atoms/Spacing';
import theme from '../../theme';
import { useDispatch } from 'react-redux';
import TextField from '../TextField';
import { setSnackbarMessage } from '../../store/slices/appSlice';
import {
  useSocialsQuery,
  useStoreSocialsMutation,
} from '../../store/socials/api';
import { ISocial } from '../../store/socials/types';

const SocialsForm: React.FC = () => {
  const dispatch = useDispatch();

  const { data: socials = [], isLoading } = useSocialsQuery();

  const [storeMultipleSocials, { isLoading: isSaving, isSuccess, isError }] =
    useStoreSocialsMutation();

  // const fields = [];
  const [fields, setFields] = useState([
    {
      name: '0',
      value: '',
    },
  ]);

  const prepareFields = (socials: ISocial[]) => {
    const newFields = [];
    if (socials.length > 0) {
      for (const index in socials) {
        const { link } = socials[index];
        newFields.push({ name: `${index}`, value: link });
      }
    }
    newFields.push({ name: `${socials.length}`, value: '' });
    return newFields;
  };

  useEffect(() => {
    setFields(prepareFields(socials));
  }, [socials]);

  const schema = object().shape(
    fields.reduce((acc, field) => {
      return {
        ...acc,
        [field.name]: string(),
      };
    }, {}),
  );

  type InputType = InferType<typeof schema>;

  const formInitialValues: InputType = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: field.value }),
    {},
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: schema,
    onSubmit: async values => {
      // console.log('---', values);
      const newSocials = await storeMultipleSocials(values).unwrap();
      setFields(prepareFields(newSocials));
      // console.log(newSocials);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setSnackbarMessage({
          message: 'Сохранено!',
          visible: true,
          severity: 'success',
        }),
      );
    }

    if (isError) {
      dispatch(
        setSnackbarMessage({
          message: 'Что-то пошло не так. Попробуйте позже.',
          visible: true,
          severity: 'error',
        }),
      );
    }
  }, [isSaving]);

  return (
    <>
      {fields.map(({ name }) => (
        <TextField
          id={name}
          value={formik.values[name]}
          name={name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          key={name}
          placeholder="https://t.me/username"
          type="string"
          error={!!formik.errors[name]}
          fullWidth
          autoComplete="off"
        />
      ))}
      <Spacing size={theme.spacing(2)} />
      <Button
        color="primary"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        onClick={formik.submitForm}>
        Добавить
      </Button>
    </>
  );
};

export default SocialsForm;
