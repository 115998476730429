import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { logout, setUser } from './slice';
import type { IUser } from './types';
import { RootState } from '../index';
import getBaseUrl from '../../utils/helpers/getBaseUrl';
import { UserEditInputType } from '../../components/UserEditForm/UserEditForm';
import { setIsLoaded } from '../slices/appSlice';
import { siteApi } from '../site/api';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl('users'),
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).userState.token ||
        localStorage.getItem('access_token');

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['User'],
  endpoints: builder => ({
    getMe: builder.query<IUser, null>({
      query: () => 'me',
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));

          if (data) {
            // receive info about site
            await dispatch(
              siteApi.endpoints.getMySite.initiate(null, {
                forceRefetch: true,
              }),
            );
          }
        } catch (error) {
          // console.error(error);
        }

        dispatch(setIsLoaded(true));
      },
    }),
    getEvents: builder.query<{ created: string; amount: number }[], null>({
      query: () => 'events',
    }),
    editUser: builder.mutation<IUser, UserEditInputType>({
      query: ({ ...patch }) => ({
        url: 'edit',
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {}
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: 'me/logout',
          method: 'POST',
          body: {
            token: localStorage.getItem('access_token'),
          },
          // credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetEventsQuery,
  useEditUserMutation,
  useLogoutUserMutation,
} = userApi;
